<template>
    <div class="container auth-page">
        <div class="text-center mb-3">
            <img src="/img/naccet.png" style="height: 50px" />
            <img src="/img/USAID.png" style="height: 50px" />
        </div>
        <div class="d-flex justify-content-center h-100">
            <div class="card">
                <div class="card-header">
                    <h3>{{appName}}</h3>
<!--                    <div class="d-flex justify-content-end social_icon">
                        <span :disabled="!gLoading" @click="google">
                            <b-spinner v-if="loading"></b-spinner>
                            <i v-else class="fab fa-google-plus-square"></i>
                        </span>
                    </div>-->
                </div>
                <div class="card-body">
                    <form method="post" @submit="login">
                        <div class="input-group form-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text"><i class="fas fa-user"></i></span>
                            </div>
                            <b-form-input name="username" v-model="form.username" class="form-control" placeholder="Nhập địa chỉ email" />
                        </div>
                        <div class="input-group form-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text"><i class="fas fa-key"></i></span>
                            </div>
                            <b-form-input name="pwd" v-model="form.pwd" type="password" class="form-control" placeholder="Nhập mật khẩu" />
                        </div>
                        <div class="row align-items-center remember">
                            <b-form-checkbox class="ml-2 mb-2" v-model="form.remember">
                                <span class="label-text">Tự động đăng nhập</span>
                            </b-form-checkbox>
                        </div>
                        <div class="row">
                            <div class="col-12" v-if="errors.username || errors.pwd" >
                                <div class="alert alert-danger">
                                    <span v-for="error in errors" :key="error[0]">{{error[0]}}<br/></span>
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <b-button :disabled="loading" variant="primary" pill class="btn-block" type="submit">
                                <b-spinner v-if="loading" small></b-spinner>
                                <em v-else class="fa fa-sign-in-alt" ></em>
                                Đăng nhập
                            </b-button>
                        </div>
                    </form>
                </div>
                <div class="card-footer">
                    <div class="d-flex justify-content-center">
                        <a href="/#/forgot">Quên mật khẩu</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style type="text/css">
    @import '../../assets/css/auth.css';
</style>
<script>
    import _ from 'lodash';
    import Vue from 'vue';
    import GAuth from 'vue-google-oauth2';

    Vue.use(GAuth, {
        clientId: process.env.VUE_APP_GOOGLE_ID,
        scope: 'profile email',
        prompt: 'select_account'
    });

    export default {
        data: function () {
            return {
                appName: '...',
                form: {
                    username: '',
                    pwd: ''
                },
                errors: {},
                loading: false,
                gLoading: false
            }
        },
        methods: {
            login: function (evt) {
                evt.preventDefault();
                this.errors = {};
                this.loading = true;
                this.$service.post('/site/login', this.form).then(response => {
                    response.data.remember = this.form.remember;
                    return this.$store.dispatch('login', response.data);
                }).then(() => {
                    if (this.$route.query.redirect) {
                        location.href = '#' + this.$route.query.redirect;
                    } else {
                        location.href = '#/';
                    }
                }).catch(error => {
                    this.errors = error.response.data;
                }).finally(() => {
                    _.delay(() => {
                        this.loading = false;
                    }, 250);
                });
            },
            google: function () {
                let vm = this;
                this.$gAuth.getAuthCode(function (code) {
                    let formModel = {
                        username: code,
                        pwd: 'sso'
                    };

                    vm.loading = true;
                    vm.$service.post('/site/sso', formModel).then(response => {
                        response.data.remember = 1;
                        return vm.$store.dispatch('login', response.data);
                    }).then(() => {
                        if (this.$route.query.redirect) {
                            location.href = '#' + this.$route.query.redirect;
                        } else {
                            location.href = '#/';
                        }
                    }).catch(error => {
                        vm.errors = error.response.data;
                    }).finally(() => {
                        _.delay(() => {
                            vm.loading = false;
                        }, 250);
                    });
                }, function (error) {
                    vm.$swal({
                        text: error.response,
                        icon: 'error'
                    });
                });
            },
        },
        async mounted() {
            if (this.$store.state.user !== null) {
                location.href = '#/';
            }
            let response = await this.$service.get('/site/etc');
            if (response.data && response.data['site-name']) {
                this.appName = response.data['site-name'];
            } else {
                this.appName = process.env.VUE_APP_NAME;
            }
            setTimeout(() => {
                this.gLoading = true;
            }, 2000);
        },
    };
</script>